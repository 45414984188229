<template>
  <div class="Personnel_box">
    <el-table :data="tableData" style="width: 100%">
      <el-table-column align="center" label="头像">
        <template slot-scope="scope">
          <img class="picture" :src="scope.row.picture" />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="username"
        label="姓名"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="phone"
        label="联系方式"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="role_name"
        label="角色"
      ></el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <div class="operation">
            <div>
              <el-button size="mini" @click="handleEdit(scope.row)">
                编辑
              </el-button>
            </div>
            <div>
              <el-popconfirm
                title="这是一段内容确定删除吗？"
                @onConfirm="handleDelete(scope.row.uid)"
              >
                <el-button slot="reference" size="mini" type="danger">
                  删除
                </el-button>
              </el-popconfirm>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div>
      <el-dialog title="编辑角色" :visible.sync="dialogFormVisible" width="30%">
        <div class="editForm">
          <div>
            <div>姓名：</div>
            <div>{{ editForm.name }}</div>
          </div>
          <div>
            <div>角色：</div>
            <div>
              <el-select
                size="small"
                v-model="editForm.role"
                placeholder="请选择角色"
                @change="switchRole"
              >
                <el-option
                  v-for="(item, index) in roleList"
                  :key="index"
                  :label="item.tilte"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false"> 取 消 </el-button>
          <el-button type="primary" @click="updataDeal()"> 确 定 </el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 人员信息
      tableData: [],
      // 角色列表
      roleList: [],
      // 编辑表单
      editForm: {
        id: "",
        uid: "",
        name: "",
        role: "",
      },
      // 弹窗状态
      dialogFormVisible: false,
    };
  },
  created() {
    this.getPersonnelList();
    this.getRoleList();
  },
  methods: {
    // 获取人员列表
    getPersonnelList() {
      this.$axios({
        url: this.$api + "/admin/fodder/user/list",
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.tableData = res.data.data;
        }
      });
    },
    // 获取角色列表
    getRoleList() {
      this.$axios({
        url: this.$api + "/admin/fodder/role/list",
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.roleList = res.data.data;
        }
      });
    },
    // 打开编辑弹窗
    handleEdit(row) {
      this.editForm.uid = row.uid;
      this.editForm.name = row.username;
      this.editForm.role = row.role_name;
      this.roleList.map((item) => {
        if (item.tilte == row.role_name) {
          this.editForm.id = item.id;
        }
      });
      this.dialogFormVisible = true;
    },
    // 选择角色
    switchRole(id) {
      this.editForm.id = id;
    },
    // 确认修改
    updataDeal() {
      let info = this.editForm;
      this.$axios({
        url: this.$api + "/admin/fodder/user/deal",
        method: "PUT",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8;",
        },
        params: {
          staff_id: info.uid,
          role_id: info.id,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            showClose: true,
            message: "修改成功",
            type: "success",
          });
          this.getPersonnelList();
          this.dialogFormVisible = false;
        }
      });
    },
    // 确认删除
    handleDelete(id) {
      this.$axios({
        url: this.$api + "/admin/fodder/user/deal",
        method: "DELETE",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8;",
        },
        params: {
          staff_id: id,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            showClose: true,
            message: "删除成功",
            type: "success",
          });
          this.getPersonnelList();
        }
      });
    },
  },
};
</script>

<style scoped>
.picture {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 100%;
  overflow: hidden;
}
.operation {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.Personnel_box {
  width: 90%;
  padding: 20px;
}

/* 编辑弹窗样式 */
.editForm {
  font-size: 16px;
}
.editForm > div {
  display: flex;
  justify-content: flex-start;
  margin: 20px 5px;
}
.editForm > div > div:nth-of-type(1) {
  font-weight: 600;
  margin-right: 10px;
}
</style>